import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toastError, toastSuccess } from "../guestSlices/other.slice"
import { BASEURI } from "../../utils/helper"
import router from "../../routes/routes"
import { Cookies } from "react-cookie"
import axios from "axios"

const cookies = new Cookies()

// Handle admin login request
export const adminLoginReq = createAsyncThunk("adminLoginReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/admin/login`, data,

    ).then((res) => {
      if (res.data?.success) {
        cookies.set("token", res.data.token, { path: "/" })
        dispatch(toastSuccess(res.data?.message))
        router?.navigate("/admin/dashboard")
        return res.data?.user
      }
      if (!res.data?.success) {
        dispatch(toastError(res.data?.error))
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  admin: {}
}
const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle admin login request
    builder.addCase(adminLoginReq.pending, (state) => {
      state.admin.loading = true
    })
    builder.addCase(adminLoginReq.fulfilled, (state, { payload }) => {
      delete state.admin.loading
      state.admin = payload || {}
    })
    builder.addCase(adminLoginReq.rejected, (state) => {
      delete state.admin.loading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default admin.reducer