import { array1, links, services } from "../../constant/guest/Home"
import { Link as NavLink } from "react-router-dom"
import MuiIcons from "../../assets/js/muiIcons"
import Images from "../../assets/images"
import { Fragment } from "react"
import {
  Box, Button, Container,
  Grid2 as Grid, ListItem, ListItemIcon, ListItemText, styled,
  Typography
} from "@mui/material"


const Span = styled("span")(() => ({}))
const Image = styled("img")(() => ({}))
const Paragraph = styled("p")(() => ({}))
function Home() {

  return (
    <Fragment>
      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            color: "white",
            bgcolor: "#110e24",
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box sx={{ py: 4, minHeight: "450px" }}>
              <Grid
                sx={{
                  alignItems: "center",
                  placeContent: "center",
                }}
                container
                spacing={1}
              >
                <Grid size={{ xs: 12, md: 9 }}>
                  <Typography
                    sx={{
                      mb: 3,
                      lineHeight: "1.2",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      fontSize: { xs: "30px", md: "40px" },
                    }}
                    component="h1"
                  >AI-driven resident enabled property compliance system
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    <Grid container spacing={{ xs: 1, md: 2 }}>
                      {links?.map((link, i) => (
                        <Grid
                          key={i}
                          size={{ xs: 12, lg: 6 }}
                        >
                          <Button
                            sx={{
                              fontWeight: "400",
                              textWrap: "nowrap",
                              letterSpacing: "1px",
                              fontFamily: "Poppins",
                              textTransform: "unset",
                              textDecoration: "none",
                              boxShadow: "none !important",
                              fontSize: { xs: "14px", md: "15px" },
                              bgcolor: "transparent !important",
                            }}
                            size="small"
                            disableRipple
                            variant="contained"
                            LinkComponent={NavLink}
                            startIcon={<MuiIcons.PlayArrowOutlined
                              sx={{
                                fontSize: {
                                  xs: "24px !important",
                                  md: "34px !important",
                                }
                              }}
                            />}
                          >{link}
                          </Button>
                        </Grid>
                      ))
                      }
                    </Grid>
                  </Box>
                  <Button
                    sx={{
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "400",
                      textWrap: "nowrap",
                      bgcolor: "#00cada",
                      borderRadius: "30px",
                      fontFamily: "Poppins",
                      textTransform: "unset",
                      textDecoration: "none",
                      boxShadow: "none !important",
                      transition: "all ease 350ms",
                    }}
                    size="large"
                    to="/support"
                    variant="contained"
                    LinkComponent={NavLink}
                  >Book a Demo
                  </Button>
                  <Button
                    sx={{
                      ml: 1,
                      fontSize: "20px",
                      fontWeight: "400",
                      textWrap: "nowrap",
                      borderRadius: "30px",
                      fontFamily: "Poppins",
                      textTransform: "unset",
                      textDecoration: "none",
                      transition: "all ease 350ms",
                      boxShadow: "none !important",
                      bgcolor: "transparent !important",
                    }}
                    size="large"
                    to="/product"
                    variant="contained"
                    LinkComponent={NavLink}
                  >Learn more
                  </Button>
                </Grid>
                <Grid size={{ xs: 12, xl: 3 }} sx={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      // height: "100%",
                      // width: "100%",
                      overflow: "hidden",
                      maxHeight: "500px",
                      borderRadius: "8px",
                      display: "inline-block",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      // minHeight: { xs: "300px", md: "400px" },
                      boxShadow: "0px 0px 10px 0px #3c3c3c99",
                    }}
                  >
                    <Image
                      alt="img"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain"
                      }}
                      src={Images?.screenshot_1}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            py: 4,
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box
              sx={{
                py: 4,
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  mb: 2.5,
                  lineHeight: "1.2",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                  fontSize: { xs: "32px", md: "44px" },
                }}
                component="h5"
              >Residents are sick of the crap
              </Typography>
            </Box>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box sx={{ height: "100%" }}>
                <Grid container spacing={1} sx={{ height: "100%" }}>
                  {services?.map((service, i) => (
                    <Grid
                      key={i}
                      size={{ xs: 12, sm: 6, md: 4 }}
                      sx={{
                        gap: "12px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          mx: "auto",
                          width: "100%",
                          color: "white",
                          display: "grid",
                          aspectRatio: "1/1",
                          bgcolor: "#110e24",
                          borderRadius: "500px",
                          placeContent: "center",
                          maxWidth: { xs: "100px", md: "150px" },
                        }}
                      >{service?.icon}
                      </Box>
                      <Paragraph
                        sx={{
                          fontWeight: "600",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: { xs: "20px", md: "30px" },
                        }}
                      >{service?.title}
                      </Paragraph>
                      <Paragraph
                        sx={{
                          fontWeight: "300",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: { xs: "15px", md: "16px" },
                        }}
                      >{service?.subTitle}
                      </Paragraph>
                    </Grid>
                  ))
                  }
                </Grid>
              </Box>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            px: 2,
            bgcolor: "#ebffff",
            borderRadius: "8px",
          }}
        >
          <Container maxWidth="xxl">
            <Box sx={{ py: 4, minHeight: "450px" }}>
              <Grid container spacing={1} sx={{ placeContent: "center", alignItems: "center" }}>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Typography
                    component="h1"
                    sx={{
                      mb: 3,
                      lineHeight: "1.2",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                      fontSize: { xs: "30px", md: "40px" },
                    }}
                  >Community Compliant&copy; allows residents to report neighbor violations and share in the revenue.
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    <Grid container spacing={2}>
                      {array1?.map((item, i) => (
                        <Grid key={i} size={{ xs: 12, lg: 6 }}>
                          <ListItem sx={{ alignItems: "flex-start" }}>
                            <ListItemIcon sx={{ minWidth: "45px" }}>
                              <Span
                                sx={{
                                  lineHeight: "1",
                                  color: "#00cada",
                                  fontSize: "60px",
                                  display: "inline-block",
                                }}
                              >{i + 1}</Span>
                            </ListItemIcon>
                            <ListItemText sx={{ flexGrow: "unset", m: "0" }}>
                              <Box>
                                <Paragraph
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                  }}
                                >{item?.title}</Paragraph>
                                <Paragraph
                                  sx={{ fontSize: "15px" }}
                                >{item?.subTitle}</Paragraph>
                              </Box>
                            </ListItemText>
                          </ListItem>
                          {/* <Box sx={{ gap: 1, display: "flex" }}>

                            
                          </Box> */}
                        </Grid>
                      ))
                      }
                    </Grid>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, xl: 3 }} sx={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      // height: "100%",
                      // width: "100%",
                      overflow: "hidden",
                      maxHeight: "500px",
                      borderRadius: "8px",
                      display: "inline-block",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      // minHeight: { xs: "300px", md: "400px" },
                      boxShadow: "0px 0px 10px 0px #3c3c3c99",
                    }}
                  >
                    <Image
                      alt="img"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain"
                      }}
                      src={Images?.screenshot_1}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Home