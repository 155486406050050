import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { BASEURI, headers } from "../../utils/helper"
import axios from "axios"
import { toastSuccess } from "../guestSlices/other.slice"

// Upload request to admin
export const handleSaveAdminReq = createAsyncThunk("handleSaveAdminReq", (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/user/chat/create-request`, data, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess("Reqest submited!"))
        return res.data
      }
    }).catch((err) => {
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get all requests
export const getAllRequests = createAsyncThunk("handleSaveAdminReq", (_, { rejectWithValue }) => {
  try {
    const response = axios.get(`${BASEURI}/user/all-requests`, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  request: {}
}
const request = createSlice({
  name: "request",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Upload request to admin
    builder.addCase(handleSaveAdminReq.pending, (state) => {
      state.request.loading = true
    })
    builder.addCase(handleSaveAdminReq.fulfilled, (state) => {
      delete state.request.loading
    })
    builder.addCase(handleSaveAdminReq.rejected, (state) => {
      delete state.request.loading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default request.reducer