import { configureStore } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import rootReducer from "./rootReducer"

const persistConfig = {
  storage,
  key: "root",
  version: 1,
  blacklist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (prev) => prev({ serializableCheck: false })
})

export default store;