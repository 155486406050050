import { handleSaveAdminReq } from "../../redux/userSlices/request.slice"
import { toastError } from "../../redux/guestSlices/other.slice"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useRef, useState } from "react"
import MuiIcons from "../../assets/js/muiIcons"
import { Link } from "react-router-dom"

import {
  handleContentGenerationReq,
  handleUploadFileReq
} from "../../redux/userSlices/chat.slice"

import {
  Box, Button, CircularProgress,
  Container, Grid2 as Grid,
  Skeleton, Stack, styled,
  Typography
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
})

function Upload() {
  const fileRef = useRef()
  const dispatch = useDispatch()
  const [res, setRes] = useState("")
  const { file, chat } = useSelector(state => state.chatSlice)
  const { request } = useSelector(state => state.requestSlice)

  const handleUpload = (e) => {
    const fileType = e.target.files[0]?.name.split(".").pop()
    const validFiles = ["jpeg", "jpg", "png"]
    if (!validFiles?.includes(fileType)) {
      dispatch(toastError("Invalid file type!"))
    }
    dispatch(handleUploadFileReq(e.target.files[0])
    ).unwrap().then((data) => {
      dispatch(handleContentGenerationReq(data?.fileURL)
      ).unwrap().then((res) => {
        setRes(res)
      })
    })
    e.target.value = ""
  }

  const handleSaveReq = () => {
    dispatch(handleSaveAdminReq({
      ...file,
      promptResponse: res
    }))
  }

  return (
    <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
      <Container maxWidth="xxl">
        <Grid container spacing={3} sx={{ justifyContent: "center" }}>
          <Grid size={{ xs: 12, sm: 10, md: 6 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid size={12} sx={{ textAlign: "end" }}>
                  <Button
                    sx={{
                      fontSize: "16px",
                      minWidth: "150px",
                      fontWeight: "400",
                      bgcolor: "#00cada",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      boxShadow: "none !important"
                    }}
                    to="/user/history"
                    variant="contained"
                    LinkComponent={Link}
                  >View all requests
                  </Button>
                </Grid>

                <Grid size={12}>
                  <Button
                    sx={{
                      py: 5,
                      width: "100%",
                      color: "#110e24",
                      fontSize: "28px",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      flexDirection: "column",
                      textTransform: "capitalize",
                      border: "1px dashed #110e24",
                    }}
                    component="label"
                    variant="outlined"
                    disabled={file?.loading}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      {file?.loading ?
                        <CircularProgress
                          sx={{
                            mr: 1,
                            color: file?.loading ? "#00000042" : "#110e24",
                          }}
                          size={30}
                          thickness={6}
                        /> :
                        <MuiIcons.UploadFileRounded sx={{ fontSize: "32px !important", mr: 1 }} />
                      }
                      {file?.loading ?
                        "Uploading.." :
                        "Upload file"
                      }
                    </Box>
                    <Paragraph
                      sx={{
                        mt: 1,
                        opacity: "0.7",
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                      }}
                    >Supported files : JPEG, PNG, JPG
                    </Paragraph>
                    <VisuallyHiddenInput
                      type="file"
                      ref={fileRef}
                      onChange={handleUpload}
                    />
                  </Button>
                </Grid>

                <Grid size={12}>
                  {chat?.loading &&
                    <Typography
                      sx={{
                        mb: 2,
                        opacity: "0.7",
                        color: "#110e24",
                        fontSize: "32px",
                        textAlign: "center",
                        fontFamily: "Poppins",
                      }}
                    >Generating Content..
                    </Typography>
                  }
                  {chat?.loading ?
                    <Fragment>
                      <Skeleton sx={{ height: "30px" }} />
                      <Skeleton sx={{ height: "30px" }} />
                      <Skeleton sx={{ height: "30px", width: "50%" }} />
                    </Fragment> :
                    res &&
                    <Fragment>
                      <Typography
                        sx={{
                          mt: 1,
                          opacity: "0.7",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                        }}
                        variant="body2"
                      >{res}
                      </Typography>
                      <Stack
                        spacing={1}
                        sx={{ mt: 3 }}
                        direction="row"
                      >
                        <Button
                          sx={{
                            fontSize: "16px",
                            minWidth: "150px",
                            fontWeight: "400",
                            bgcolor: "#00cada",
                            fontFamily: "Poppins",
                            textTransform: "capitalize",
                          }}
                          variant="contained"
                          onClick={handleSaveReq}
                          endIcon={request?.loading &&
                            <CircularProgress
                              size={20}
                              thickness={6}
                              sx={{ color: "white" }}
                            />
                          }
                        >save
                        </Button>
                      </Stack>
                    </Fragment>
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>

        </Grid>
      </Container>
    </Box>
  )
}

export default Upload