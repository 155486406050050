import { Button, IconButton, ListItem, ListItemIcon, ListItemText, Stack, styled } from "@mui/material"
import MuiIcons from "../../assets/js/muiIcons"
import { menus } from "../../constant/admin/Sidebar"
import { Link, useLocation } from "react-router-dom"

const Paragraph = styled("p")(() => ({}))
const SidebarContainer = styled("div")(() => ({
  top: "0",
  bottom: "0",
  zIndex: "200",
  width: "250px",
  height: "100vh",
  position: "fixed",
  overflowY: "auto",
  backgroundColor: "#ceffff",
  transitionDuration: "350ms",
}))
function Sidebar({ active, sidebarDeActive }) {
  const { pathname } = useLocation()

  return (
    <SidebarContainer
      as="aside"
      sx={{ left: { xs: active ? "0" : "-100%", laptop: "0" } }}
    >
      <ListItem component="div" sx={{ mt: 1 }}>
        <ListItemIcon sx={{ minWidth: "auto" }}>
          <MuiIcons.AdminPanelSettingsRounded
            sx={{ fontSize: "28px", mr: 1, color: "#110e24" }} />
        </ListItemIcon>
        <ListItemText sx={{ flexGrow: "unset", m: "0" }}>
          <Paragraph
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              fontFamily: "Montserrat"
            }}
          >Admin
          </Paragraph>
        </ListItemText>
        <ListItemIcon
          sx={{
            ml: "auto",
            minWidth: "auto",
            display: { xs: "flex", laptop: "none" }
          }}>
          <IconButton
            size="small"
            onClick={sidebarDeActive}
          ><MuiIcons.ClearRounded
              sx={{ fontSize: "20px", color: "#110e24" }}
            />
          </IconButton>
        </ListItemIcon>
      </ListItem>

      <Stack
        sx={{
          mt: 2,
          px: 2,
        }}
        spacing={1}
      >
        {menus?.map((menu, i) => (
          <Button
            sx={{
              fontSize: "17px",
              fontWeight: "500",
              fontFamily: "Poppins",
              justifyContent: "start",
              textTransform: "capitalize",
              boxShadow: "none !important",
              color: pathname === menu?.path ? "white" : "#110e24",
              ":hover": pathname !== menu?.path && { bgcolor: "white" },
              bgcolor: pathname === menu?.path ? "#00cada" : "transparent",
            }}
            key={i}
            to={menu?.path}
            variant="contained"
            LinkComponent={Link}
            startIcon={menu?.icon}
          >{menu?.name}
          </Button>
        ))
        }
      </Stack>
    </SidebarContainer>
  )
}

export default Sidebar