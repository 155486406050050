import { Avatar, Box, Grid2 as Grid, styled, Typography } from "@mui/material"
import { Fragment } from "react"
import { useSelector } from "react-redux"

const Span = styled("span")(() => ({ display: "inline-block" }))
function Profile() {
  const { admin } = useSelector(state => state.adminSlice)

  return (
    <Fragment>
      <Box sx={{ p: 3, width: { xs: "100vw", laptop: "calc(100vw - 250px)" } }}>
        <Grid container spacing={4} sx={{ alignItems: "center" }}>
          <Grid size={{ xs: 12, sm: "auto" }}>
            <Avatar
              sx={{
                color: "#00cada",
                bgcolor: "#ceffff",
                width: { xs: "75px", md: "150px" },
                height: { xs: "75px", md: "150px" },
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: "auto" }}>
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "Poppins",
                textTransform: "capitalize",
                fontSize: { xs: "24px", md: "32px" },
              }}
              component="h2"
            >{admin?.fullName}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: { xs: "16px", md: "18px" },
              }}
            >{admin?.email}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ py: 3 }}>
          <Typography
            sx={{
              mb: 2,
              fontFamily: "Poppins",
              fontSize: { xs: "16px", md: "18px" },
            }}
          >
            <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Name : &nbsp;</Span>
            <Span sx={{ fontWeight: "400" }}>{admin?.fullName}</Span>
          </Typography>
          <Typography
            sx={{
              mb: 2,
              fontFamily: "Poppins",
              fontSize: { xs: "16px", md: "18px" },
            }}
          >
            <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Email : &nbsp;</Span>
            <Span sx={{ fontWeight: "400" }}>{admin?.email}</Span>
          </Typography>
          <Typography
            sx={{
              mb: 2,
              fontFamily: "Poppins",
              fontSize: { xs: "16px", md: "18px" },
            }}
          >
            <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Role : &nbsp;</Span>
            <Span sx={{ fontWeight: "400" }}>{admin?.role}</Span>
          </Typography>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Profile