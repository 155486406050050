import img_1 from "./img-1.jpg"
import img_2 from "./img-2.jpg"
import img_3 from "./img-3.jpg"
import img_4 from "./img-4.jpg"
import img_5 from "./img-5.jpg"
import screenshot_1 from "./screenshot-1.png"

const Images = {
  img_1,
  img_2,
  img_3,
  img_4,
  img_5,
  screenshot_1,
}

export default Images