import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASEURI, headers } from "../../utils/helper";
import axios from "axios";

// Get all requests
export const getAllRequests = createAsyncThunk("getAllRequests", ({ page, search }, { rejectWithValue }) => {
  const params = {}
  if (page) { params.page = page }
  if (search) { params.search = search }
  try {
    const response = axios.get(`${BASEURI}/admin/all-requests`, {
      params,
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  request: {}
}
const request = createSlice({
  name: "request",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get all requests
    builder.addCase(getAllRequests.pending, (state) => {
      state.request.allLoading = true
    })
    builder.addCase(getAllRequests.fulfilled, (state, { payload }) => {
      delete state.request.allLoading
    })
    builder.addCase(getAllRequests.rejected, (state) => {
      delete state.request.allLoading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default request.reducer