import { Outlet, useNavigate } from "react-router-dom"
import Toaster from "../common/Toaster"
import { Box } from "@mui/material"
import Header from "../guest/Header"
import Footer from "../guest/Footer"
import { useEffect } from "react"
import { checkToken } from "../../utils/helper"

function Layout() {
  const navigate = useNavigate()

  useEffect(() => {
    if (!checkToken()) {
      return navigate("/login")
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Box
      sx={{
        display: "grid",
        minHeight: "100vh",
        gridTemplateRows: "auto 1fr auto"
      }}
    >
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
      <Toaster />
    </Box>
  )
}

export default Layout