import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { BASEURI, headers } from "../../utils/helper"
import axios from "axios"

// Upload file request
export const handleUploadFileReq = createAsyncThunk("handleUploadFileReq", (file, { rejectWithValue }) => {
  const formData = new FormData()
  formData.append("file", file)
  try {
    const response = axios.post(`${BASEURI}/user/chat/upload`, formData, {
      headers: {
        ...headers(),
        "Content-Type": "multipart/form-data",
      }
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Generate content request
export const handleContentGenerationReq = createAsyncThunk("handleContentGenerationReq", (url, { rejectWithValue }) => {
  try {
    const response = axios.post(`${BASEURI}/user/chat/response`, { url }, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  file: {},
  chat: {},
}
const chat = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Upload file request
    builder.addCase(handleUploadFileReq.pending, (state) => {
      state.file.loading = true
    })
    builder.addCase(handleUploadFileReq.fulfilled, (state, { payload }) => {
      delete state.file.loading
      state.file = payload
    })
    builder.addCase(handleUploadFileReq.rejected, (state) => {
      delete state.file.loading
    })

    // Generate content request
    builder.addCase(handleContentGenerationReq.pending, (state) => {
      state.chat.loading = true
    })
    builder.addCase(handleContentGenerationReq.fulfilled, (state, { payload }) => {
      delete state.chat.loading
      // state.chat.data = payload
    })
    builder.addCase(handleContentGenerationReq.rejected, (state) => {
      delete state.chat.loading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default chat.reducer