import { getAllRequests } from "../../redux/adminSlices/request.slice"
import { requestsTableHeader } from "../../constant/admin/Tables"
import SearchInput from "../../components/common/SearchInput"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useState } from "react"
import MuiIcons from "../../assets/js/muiIcons"
import {
  Avatar, Box, IconButton, Pagination,
  Skeleton, Table, TableBody,
  TableCell, TableContainer,
  TableHead, TableRow, Typography
} from "@mui/material"

function Users() {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { request } = useSelector(state => state.requestAdSlice)

  const getRequestsByPage = (n) => {
    dispatch(getAllRequests({
      page: n,
      // search: searchRef?.current?.value
      search: null
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  useEffect(() => {
    dispatch(getAllRequests({
      page: null,
      search: null
    })
    ).unwrap().then((res) => {
      setData(res)
    })

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Box sx={{ p: 3, width: { xs: "100vw", laptop: "calc(100vw - 250px)" } }}>
        <Box
          sx={{
            mb: 3,
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              color: "#110e24",
              fontSize: "20px",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
          >Found Request : {data?.totalUsers}
          </Typography>
          <Box sx={{ maxWidth: "350px", ml: "auto" }}>
            <SearchInput />
          </Box>
        </Box>

        <TableContainer
          sx={{
            maxWidth: "100%",
            borderRadius: "8px",
            boxShadow: "0px 0px 20px 0px #4545452e"
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {requestsTableHeader?.map((cell, i) => (
                  <TableCell
                    key={i}
                    align="left"
                    sx={{
                      py: 1,
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "500",
                      minWidth: "100px",
                      bgcolor: "#00cada",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    }}
                  >{cell}
                  </TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {request?.allLoading ?
                new Array(5)?.fill()?.map((_, i) => (
                  <TableRow key={i}>
                    {new Array(5)?.fill()?.map((cell, i) => (
                      <TableCell
                        key={i}
                        align="left"
                        sx={{
                          py: 0.5,
                          minWidth: "100px",
                        }}
                      ><Skeleton sx={{ height: "30px" }} />
                      </TableCell>
                    ))
                    }
                  </TableRow>
                )) :
                data?.allRequest?.map((req, i) => (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    ><Avatar
                        sx={{
                          width: "28px",
                          height: "28px",
                          color: "white",
                          bgcolor: "#00cada",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                      align="left"
                    >{req?.user?.fullName}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                      }}
                      align="left"
                    >{req?.user?.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        fontFamily: "Poppins",
                      }}
                      align="left"
                    >{req?.status}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    ><IconButton
                      size="small"
                      sx={{ mr: 1 }}
                    ><MuiIcons.RemoveRedEyeRounded sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                      <IconButton
                        size="small"
                      ><MuiIcons.BorderColorRounded sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }

              {!data?.allRequest?.length &&
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      py: 1,
                      color: "#110e24",
                      fontSize: "24px",
                      minWidth: "100px",
                      fontWeight: "500",
                      maxWidth: "250px",
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >No results found!
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 3 }}>
          <Pagination
            as="div"
            color="#00cada"
            shape="rounded"
            boundaryCount={3}
            count={data?.pages?.totalPages}
            onChange={(_, n) => { getRequestsByPage(n) }}
          />
        </Box>
      </Box>
    </Fragment>
  )
}

export default Users