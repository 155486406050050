import { Box, Grid2 as Grid, styled } from "@mui/material"
import { data } from "../../constant/admin/Dashboard"

const Paragraph = styled("p")(() => ({}))
function Dashboard() {
  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={2}>
        {data?.map((item, i) => (
          <Grid key={i} size={{ xs: 12, ms: 2, md: 4, lg: 3 }}>
            <Box
              sx={{
                p: 2,
                gap: 2,
                height: "100%",
                color: "white",
                display: "flex",
                bgcolor: "#00cada",
                borderRadius: "8px",
                flexDirection: "column",
                boxShadow: "0px 0px 10px 0px #00000034"
              }}
            >
              <Box
                sx={{
                  gap: 1,
                  flexGrow: "1",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item?.icon}
                <Paragraph
                  sx={{
                    fontSize: "18px",
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    textTransform: "capitalize"
                  }}
                >{item?.title}
                </Paragraph>
              </Box>
              <Paragraph
                sx={{
                  fontSize: "44px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
              >{item?.numbers}
              </Paragraph>
            </Box>
          </Grid>
        ))
        }
      </Grid>
    </Box>
  )
}

export default Dashboard