import MuiIcons from "../../assets/js/muiIcons";

export const data = [
  {
    numbers: 3211,
    title: "total users",
    icon: <MuiIcons.GroupRounded sx={{ fontSize: "32px" }} />
  },
  {
    numbers: 324,
    title: "total request",
    icon: <MuiIcons.ForumRounded sx={{ fontSize: "32px" }} />
  },
  {
    numbers: 100,
    title: "pending request",
    icon: <MuiIcons.MarkChatUnreadRounded sx={{ fontSize: "32px" }} />
  },
  {
    numbers: 224,
    title: "approved request",
    icon: <MuiIcons.MarkChatReadRounded sx={{ fontSize: "32px" }} />
  },
]