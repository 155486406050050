export const faqs = [
  {
    question: "What is Community Compliant?",
    answer: "Community Compliant is an AI-driven, resident-enabled property compliance system allowing residents to report neighbors for pet waste violations and off-leash violations.",
  },
  {
    question: "How does it work?",
    answer: "Reporting residents snap a picture of an offending resident committing a violation and upload it to our system. Our proprietary AI technology analyzes the photo and matches the dog details against details recorded in the property management system. Onsite team is notified of the submission via email.",
  },
  {
    question: "How does the resident benefit?",
    answer: "Residents are able to easily report a frustration point and know that it will be addressed. In addition, they are incentivized to earn rewards and live in a cleaner community.",
  },
  {
    question: "What is the benefit to the property?",
    answer: "Properties benefit by shifting the onus of policing every negative resident action, earning additional revenue, and disincentivizing negative resident behavior, ultimately increasing resident satisfaction.",
  },
]