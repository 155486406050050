import { Box, Skeleton, styled } from "@mui/material"
import { useState } from "react"

const Image = styled("img")(() => ({}))
function ImgWithSkel({ alt, src }) {
  const [show, setShow] = useState(true)

  return (
    <Box
      sx={{
        width: "200px",
        height: "150px",
        minWidth: "200px",
      }}
    >
      {show &&
        <Skeleton
          sx={{
            height: "150px",
            width: "200px",
            transform: "none"
          }}
          animation="wave"
        />
      }
      <Image
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "6px",
          display: show ? "none" : "block"
        }}
        src={src}
        alt={alt || "img"}
        onLoad={() => { setShow(false) }}
      />
    </Box>
  )
}

export default ImgWithSkel