import Layout from "../components/guest/Layout"
import Signup from "../pages/guest/Signup"
import Login from "../pages/guest/Login"
import { Route } from "react-router-dom"
import Home from "../pages/guest/Home"
import FAQs from "../pages/guest/FAQs"
import Support from "../pages/guest/Support"
import Product from "../pages/guest/Product"

const GuestRoutes = (
  <Route path="/" element={<Layout />}>
    <Route path="" element={<Home />} />
    <Route path="home" element={<Home />} />
    <Route path="/faqs" element={<FAQs />} />
    <Route path="login" element={<Login />} />
    <Route path="sign-up" element={<Signup />} />
    <Route path="/support" element={<Support />} />
    <Route path="/product" element={<Product />} />
  </Route>
)

export default GuestRoutes