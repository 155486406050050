import Layout from "../components/user/Layout"
import { Route } from "react-router-dom"
import Upload from "../pages/user/Upload"
import History from "../pages/user/History"

const UserRoutes = (
  <Route path="/user" element={<Layout />}>
    <Route path="upload" element={<Upload />} />
    <Route path="history" element={<History />} />
  </Route>
)

export default UserRoutes