import { getAllUsersReq } from "../../redux/adminSlices/users.slice"
import SearchInput from "../../components/common/SearchInput"
import { userTableHeader } from "../../constant/admin/Tables"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useRef, useState } from "react"
import MuiIcons from "../../assets/js/muiIcons"
import {
  Avatar, Box, IconButton, Pagination,
  Skeleton, Table, TableBody,
  TableCell, TableContainer,
  TableHead, TableRow, Typography
} from "@mui/material"

function Users() {
  const searchRef = useRef("")
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { users } = useSelector(state => state.usersSlice)
  let searchInterver;

  const handleSearch = () => {
    clearInterval(searchInterver)
    searchInterver = setTimeout(() => {
      dispatch(getAllUsersReq({
        page: null,
        search: searchRef?.current?.value
      })).unwrap().then((res) => {
        setData(res)
      })
    }, 2000)
  }

  const getUsersByPage = (n) => {
    dispatch(getAllUsersReq({
      page: n,
      search: searchRef?.current?.value
    })).unwrap().then((res) => {
      setData(res)
    })
  }

  useEffect(() => {
    dispatch(getAllUsersReq({
      page: null,
      search: null
    })).unwrap().then((res) => {
      setData(res)
    })

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Box sx={{ p: 3, width: { xs: "calc(100vw - 24px)", laptop: "calc(100vw - 298px)" } }}>
        <Box
          sx={{
            mb: 3,
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              color: "#110e24",
              fontSize: "20px",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
          >Found Users : {data?.totalUsers}
          </Typography>
          <Box sx={{ maxWidth: "350px", ml: "auto" }}>
            <SearchInput
              ref={searchRef}
              onChangeFun={handleSearch}
            />
          </Box>
        </Box>

        <TableContainer
          sx={{
            maxWidth: "100%",
            borderRadius: "8px",
            boxShadow: "0px 0px 20px 0px #4545452e"
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {userTableHeader?.map((cell, i) => (
                  <TableCell
                    key={i}
                    align="left"
                    sx={{
                      py: 1,
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "500",
                      minWidth: "100px",
                      bgcolor: "#00cada",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    }}
                  >{cell}
                  </TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.allLoading ?
                new Array(5)?.fill()?.map((_, i) => (
                  <TableRow key={i}>
                    {new Array(5)?.fill()?.map((cell, i) => (
                      <TableCell
                        key={i}
                        align="left"
                        sx={{
                          py: 0.5,
                          minWidth: "100px",
                        }}
                      ><Skeleton sx={{ height: "30px" }} />
                      </TableCell>
                    ))
                    }
                  </TableRow>
                )) :
                data?.users?.map((user, i) => (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    ><Avatar
                        sx={{
                          width: "28px",
                          height: "28px",
                          color: "white",
                          bgcolor: "#00cada",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize",
                      }}
                      align="left"
                    >{user?.fullName}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        maxWidth: "250px",
                        textWrap: "nowrap",
                        overflow: "hidden",
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                      }}
                      align="left"
                    >{user?.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 1,
                        fontSize: "15px",
                        minWidth: "100px",
                        fontFamily: "Poppins",
                      }}
                      align="left"
                    >{user?.role}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ py: 1 }}
                    ><IconButton
                      size="small"
                      sx={{ mr: 1 }}
                    ><MuiIcons.RemoveRedEyeRounded sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                      <IconButton
                        size="small"
                      ><MuiIcons.BorderColorRounded sx={{ fontSize: "20px", color: "#00cada" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }

              {(!data?.users?.length && !users?.allLoading) &&
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      py: 1,
                      color: "#110e24",
                      fontSize: "24px",
                      minWidth: "100px",
                      fontWeight: "500",
                      maxWidth: "250px",
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >No results found!
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 3 }}>
          <Pagination
            as="div"
            color="#00cada"
            shape="rounded"
            boundaryCount={3}
            count={data?.pages?.totalPages}
            onChange={(_, n) => { getUsersByPage(n) }}
          />
        </Box>
      </Box>
    </Fragment>
  )
}

export default Users