import {
  YouTube,
  Twitter,
  LinkedIn,
  StarRounded,
  MenuRounded,
  PetsRounded,
  ClearRounded,
  GroupRounded,
  ForumRounded,
  SearchRounded,
  FacebookRounded,
  FavoriteRounded,
  DashboardRounded,
  UploadFileRounded,
  PlayArrowOutlined,
  DragHandleRounded,
  StarBorderOutlined,
  BorderColorRounded,
  MarkChatReadRounded,
  RemoveRedEyeRounded,
  AttachMoneyOutlined,
  VisibilityOffRounded,
  DeleteOutlineRounded,
  DeleteOutlineOutlined,
  HorizontalRuleRounded,
  MarkChatUnreadRounded,
  ArrowForwardIosRounded,
  MarkUnreadChatAltRounded,
  AdminPanelSettingsRounded,
  CheckCircleOutlineRounded,
} from "@mui/icons-material"

const MuiIcons = {
  YouTube,
  Twitter,
  LinkedIn,
  StarRounded,
  MenuRounded,
  PetsRounded,
  ClearRounded,
  GroupRounded,
  ForumRounded,
  SearchRounded,
  FacebookRounded,
  FavoriteRounded,
  DashboardRounded,
  UploadFileRounded,
  PlayArrowOutlined,
  DragHandleRounded,
  StarBorderOutlined,
  BorderColorRounded,
  MarkChatReadRounded,
  RemoveRedEyeRounded,
  AttachMoneyOutlined,
  VisibilityOffRounded,
  DeleteOutlineRounded,
  DeleteOutlineOutlined,
  HorizontalRuleRounded,
  MarkChatUnreadRounded,
  ArrowForwardIosRounded,
  MarkUnreadChatAltRounded,
  AdminPanelSettingsRounded,
  CheckCircleOutlineRounded,
}

export default MuiIcons