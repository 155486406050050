import { Box, Container, Grid2 as Grid, IconButton, Link, Stack, styled } from "@mui/material"
import { socialMedia } from "../../constant/guest/Footer"
import { Link as NavLink } from "react-router-dom"
import menus from "../../constant/guest/Header"

const Paragraph = styled("p")(() => ({}))
function Footer() {
  const year = new Date().getFullYear()

  return (
    <Box
      sx={{
        color: "white",
        bgcolor: "#110e24",
        textAlign: { xs: "center", md: "left" }
      }}
      component="footer"
    >
      <Container maxWidth="xxl">
        <Box sx={{ py: { xs: 2, sm: 4, md: 6 } }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 4 }}>
              <Link
                sx={{
                  p: "4px",
                  fontSize: "28px",
                  color: "white",
                  fontWeight: "800",
                  textDecoration: "none",
                  fontFamily: "Montserrat",
                  textTransform: "capitalize",
                }}
                to="/"
                component={NavLink}
              >Community Compliant&copy;
              </Link>
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <Stack spacing={1}>
                {menus?.map((menu, i) => (
                  <Link
                    sx={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "600",
                      textDecoration: "none",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    }}
                    key={i}
                    to={menu?.path}
                    component={NavLink}
                  >{menu?.name}
                  </Link>
                ))
                }
              </Stack>
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <Stack spacing={1}>
                <Paragraph
                  sx={{
                    color: "white",
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    textDecoration: "none",
                    textTransform: "capitalize",
                  }}
                >Follow us
                </Paragraph>
                <Box
                  sx={{
                    mt: "0",
                    gap: 1,
                    display: "flex",
                    justifyContent: { xs: "center", md: "start" }
                  }}
                >
                  {socialMedia?.map((menu, i) => (
                    <IconButton
                      key={i}
                      size="small"
                      to={menu?.link}
                      LinkComponent={Link}
                      sx={{ color: "white" }}
                    >{menu.icon}
                    </IconButton>
                  ))
                  }
                </Box>
                <Paragraph
                  sx={{
                    color: "white",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    textDecoration: "none",
                    textTransform: "capitalize",
                  }}
                >Texus, United States
                </Paragraph>
                <Link
                  sx={{
                    color: "white",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    textDecoration: "none",
                  }}
                  to="mailto:sales@communitycompliant.com"
                  component={NavLink}
                >sales@communitycompliant.com
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            py: 3,
            color: "white",
            fontSize: "16px",
          }}
        >&copy; {year} Community Compliant, Inc - Privacy - Terms</Box>
      </Container>
    </Box>
  )
}

export default Footer