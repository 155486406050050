import Dashboard from "../pages/admin/Dashboard"
import Layout from "../components/admin/Layout"
import { Route } from "react-router-dom"
import Login from "../pages/admin/Login"
import Users from "../pages/admin/Users"
import Requests from "../pages/admin/Requests"
import Profile from "../pages/admin/Profile"

const AdminRoutes = (
  <Route path="/admin" element={<Layout />}>
    <Route path="login" element={<Login />} />
    <Route path="users" element={<Users />} />
    <Route path="profile" element={<Profile />} />
    <Route path="requests" element={<Requests />} />
    <Route path="dashboard" element={<Dashboard />} />
  </Route>
)

export default AdminRoutes