import MuiIcons from "../../assets/js/muiIcons";

export const menus = [
  {
    name: "dashboard",
    path: "/admin/dashboard",
    icon: <MuiIcons.DashboardRounded sx={{ fontSize: "22px !important" }} />,
  },
  {
    name: "users",
    path: "/admin/users",
    icon: <MuiIcons.GroupRounded sx={{ fontSize: "22px !important" }} />,
  },
  {
    name: "requests",
    path: "/admin/requests",
    icon: <MuiIcons.ForumRounded sx={{ fontSize: "22px !important" }} />,
  },
]