import MuiIcons from "../../assets/js/muiIcons"

export const links = [
  "Improve online reputation",
  "Increase resident satisfaction",
  "Increase revenue",
  "Increase curb appeal",
]

export const services = [
  {
    title: "Negative Reviews",
    subTitle: "In a study conducted by J Turner, nearly 40,000 reviews mentioned pet waste. Pet waste ranked higher than Packages & Mail when looking at 1 & 2 star reviews.",
    icon: <MuiIcons.StarBorderOutlined sx={{ fontSize: { xs: "75px", md: "100px" } }} />,
  },
  {
    title: "Unclean Grounds",
    subTitle: "Dirty property grounds reduce curb appeal, anger residents, and create unsanitary conditions – all three of which lowers the property value.",
    icon: <MuiIcons.DeleteOutlineOutlined sx={{ fontSize: { xs: "75px", md: "100px" } }} />,
  },
  {
    title: "Time Wasted",
    subTitle: "Onsite teams having to field resident complaints and clean up pet waste themselves wastes time when they could be focused on enhancing the resident experience.",
    icon: <MuiIcons.AttachMoneyOutlined sx={{ fontSize: { xs: "75px", md: "100px" } }} />,
  },
]

export const array1 = [
  {
    title: "Resident snaps picture and uploads",
    subTitle: "Our AI technology determines the details of the pet and matches it with their owner.",
  },
  {
    title: "Onsite team receives notification and confirms incident",
    subTitle: "Team receives automatic notification of incident right to their email with location on the property.",
  },
  {
    title: "Offending resident is charged a violation fee",
    subTitle: "After confirming incident, offending resident is charged a lease violation fee. The property collects revenue otherwise undetected.",
  },
  {
    title: "Reporting resident is credited % of fee on next statement",
    subTitle: "Resident receives a portion of the newfound revenue as incentive to keep property maintained.",
  },
]