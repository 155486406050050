import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURI, headers } from "../../utils/helper";

// Get all users request
export const getAllUsersReq = createAsyncThunk("getAllUsersReq", ({ page, search }, { rejectWithValue }) => {
  const params = {}
  if (page) { params.page = page }
  if (search) { params.search = search }
  try {
    const response = axios.get(`${BASEURI}/admin/all-users`, {
      params,
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  users: {}
}
const users = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get all users request
    builder.addCase(getAllUsersReq.pending, (state) => {
      state.users.allLoading = true
    })
    builder.addCase(getAllUsersReq.fulfilled, (state, { payload }) => {
      delete state.users.allLoading
    })
    builder.addCase(getAllUsersReq.rejected, (state) => {
      delete state.users.allLoading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default users.reducer